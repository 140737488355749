import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1Large from "../../images/orbitz10-w500.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const Xs17PaCompactPage = () => {
  return (
    <>
      <SEO title='10" Orbital Floor Machine - NANO | Timberline Cleaning Equipment' />
      <Helmet>
        {/* <title>XS Compact Scrubber | Timberline Cleaning Equipment</title> */}
        <description>
          Clean small areas quickly with this NANO Orbital Floor Machine. With
          oscillating technology and a variety of unique pads, clean in areas
          traditional scrubbers can't compete. Stairwells, around toilets in
          Restrooms, and other typically hard to clean areas become easy to
          access and scrub. At 21 lbs, our NANO Scrubbers have a large amount of
          pressure for thorough deep-cleaning of your floor surfaces. The NANO
          Orbitz is available as corded AC or battery-powered 42V DC.{" "}
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              border="none"
              image1Large={Image1Large}
              mobileLink="#demo-section"
              mobileTitle='10" Orbital Machine - NANO'
            />
            <div>
              <ProductDescription
                productTitle='10" Orbital Floor Machine - NANO'
                subHeading="AC or DC Powered"
                modelNum="TF10R (AC) / TF42R (DC)"
                productDescription="Clean small areas quickly with this NANO Orbital Floor Machine.  With oscillating technology and a variety of unique pads, clean in areas traditional scrubbers can not compete.  Stairwells, around toilets in Restrooms, and other typically hard to clean areas become easy to access and scrub.  At 21 lbs, our NANO Scrubbers have a large amount of pressure for thorough deep-cleaning of your floor surfaces.  The NANO Orbitz is available as corded AC or battery-powered 42V DC. "
                link="#demo-section"
                list1="Easy to operate with ergonomic Trigger Handle."
                list2="Non-corrosive, noise-reducing base clip"
                list3="Choose AC or DC depending on needs."
                list4="21 lbs of head pressure"
                listStyle="none"
                // list5="Clean any hour of the day with approved sound levels < 67 dBA"
                // list6="Increase productivity with over 2 hours of run time between dump and refill"
                // list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
                brochure="../../ORBITZ-SALES-SHEET.pdf"
                tech="../../tl-orbitz-tech-specs-v21263.pdf"
                parts="../../TL-COMING-SOON.pdf"
                operator="../../TL-COMING-SOON.pdf"
                displayHood="none"
                showPM="none"
              />
            </div>
          </div>
          <div id="demo-section">
            <IndexSection3 title="Request a Free Demo at your Facility" />
          </div>
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default Xs17PaCompactPage
